<template>
  <div>
    <div class="cinzento text-center" style="width:100%;height:70px;" v-if="menu">
      <!--
      <a class="icon wb-power float-right mr-10 mt-30 ml-10" href="javascript:void(0)" @click="logout()" >
        <span class="sr-only">Sair</span>
      </a>
      <button @click="mudarSenha()" class="float-right btn btn-default ml-10 mt-20">Mudar Senha</button>
      <button v-if="resumo" @click="resumoFN()" class="float-right btn btn-warning ml-10 mt-20">Gestão de Horário</button>
      <button v-else @click="resumoFN()" class="float-right btn btn-warning ml-10 mt-20">Resumo de Horas</button>-->
      <img :src="$store.state.logoBalizaslandia" class="mao" @click="menu = 0;" style="width:95%;max-width: 100px;" />
    </div>
    <div class="row pr cinzento m-0 text-center cinzento" style="min-height:100vh;min-width:100vw" v-else>
      <div class="cinzento text-center" style="width:100%;min-height: 100vh;">
        <img :src="$store.state.logoBalizaslandia" style="width:100%; max-width: 300px;" />
        <br />
        <button v-if="espaco" style="min-width:250px;" @click="resumo = 0; menu = 1;alocados = 0;formUrl()" class="btn btn-warning mt-10 mb-10">Gestão Disponibilidade</button>
        <br />
        <button style="min-width:250px;" v-if="resumo" @click="resumoFN()" class="btn btn-warning mt-10 mb-10">Gestão de Horário</button>
        <button style="min-width:250px;" v-else @click="resumoFN(); passado = 0;" class="btn btn-warning mt-10 mb-10">Resumo de Horas</button>
        <br />
        <button style="min-width:250px;" @click="resumo = 2; menu = 1;" class="btn btn-default mt-10 mb-10">Legendas e Informação</button>
        <br />
        <a href="https://balizaslandia.pt/manual.pdf" style="min-width:250px;" class="btn btn-default mt-10 mb-10">Manual de Utilização</a>
        <br />
        <button style="min-width:250px;" @click="mudarSenha()" class="btn btn-default mt-10 mb-10">Mudar Senha</button>
        <br />
        <a class="icon wb-power block mt-10 mb-20" href="javascript:void(0)" @click="logout()" ><span class="sr-only">Sair</span></a>
      </div>
    </div>
    <div v-if="menu" class="cinzento1" style="width:100%;min-height: calc(100vh - 70px);">
      <div class=" p-5" v-if="resumo === 1" style="width:100%;">
        <h3 class="ml-20">Resumo de Horas do Mês{{!passado ? '  Corrente' : ' Passado'}}
          <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
            <div class="radio-custom radio-primary mr-20 float-left" v-if="user.balizaslandia">
              <input @change="formUrl(1); passado = 0;" class="foco" type="radio" name="espaco11" id="balizaslandia123" v-model="espaco" value="smf" checked>
              <label for="balizaslandia123">Landia SMF</label>
            </div>
            <div class="radio-custom radio-primary mr-20 float-left" v-if="user.balizaslandia_oaz">
              <input @change="formUrl(1); passado = 0;" class="foco" type="radio" name="espaco11" id="balizaslandia_oaz" v-model="espaco" value="oaz">
              <label for="balizaslandia_oaz">Landia OAZ</label>
            </div>
            <div class="radio-custom radio-primary mr-20 float-left" v-if="user.balizas">
              <input @change="formUrl(1); passado = 0;" class="foco" type="radio" name="espaco11" id="balizas123444" v-model="espaco" value="balizas">
              <label for="balizas123444">Futebol</label>
            </div>
          </div>
          <button v-if="!passado" @click="formUrl(2); passado = 2" class="btn btn-primary float-right mr-20">Mês Passado</button>
          <button v-if="passado == 2" @click="formUrl(1); passado = 0" class="btn btn-primary float-right mr-20">Mês Corrente</button>
        </h3>

        <div class="table-responsive cinzento1 pt-10" style="clear:both;" v-if="espaco === 'balizas'">
          <table id="tabelaFixa" class="table balizas" style="width:auto;margin: 0 auto;">
            <thead>
              <tr>
                <th style="top:0px;width:150px;" class="meio azul text-center">Dia</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Entrada</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Saída</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Total (Horas)</th>
                <th v-if="!user.monitores_fixos" style="top:0px;width:150px;" class="meio azul text-center">Total (Euros)</th>
                <th v-if="!user.monitores_fixos" style="top:0px;width:50px;" class="meio azul text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dt, index) in res2" :key="index">
                <td :class="{'meio slot': 1, 'text-danger': dt.faltou}">{{dt.data}} - {{semanaFN(dt)}}</td>
                <td class="text-center meio">{{meta(dt, 'Anos 2')}}</td>
                <td class="text-center meio">{{meta(dt, 'Anos 3')}}</td>
                <td class="text-center meio">{{dt.horas}} h</td>
                <td v-if="!user.monitores_fixos" class="meio text-center meio text-succes">{{meta(dt, 'valor recebido').split('#')[0] * dt.horas}} €</td>
                <td v-if="!user.monitores_fixos" class="meio text-center meio"><div :style="meta(dt, 'PP') ? '' : 'visibility: hidden;'" v-html="meta(dt, 'PP') ? check() : ''"></div></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-center meio" colspan="3">Total</td>
                <th class="text-center meio">{{horas}} h</th>
                <th v-if="!user.monitores_fixos" class="text-center meio">{{total}} €</th>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="table-responsive cinzento1 pt-10" style="clear:both;" v-else>
          <table id="tabelaFixa" class="table balizas" style="width:auto;margin: 0 auto;">
            <thead>
              <tr>
                <th style="top:0px;width:150px;" class="meio azul text-center">Dia</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Manhã Entrada</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Manhã Saída</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Tarde Entrada</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Tarde Saída</th>
                <th style="top:0px;width:150px;" class="meio azul text-center">Total (Horas)</th>
                <th v-if="!user.monitores_fixos" style="top:0px;width:150px;" class="meio azul text-center">Total (Euros)</th>
                <th v-if="!user.monitores_fixos" style="top:0px;width:50px;" class="meio azul text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dt, index) in res2" :key="index">
                <td :class="{'meio slot': 1, 'text-danger': dt.faltou}">{{dt.data}} - {{semanaFN(dt)}}</td>
                <td class="text-center meio">{{meta(dt, 'manha').split('#')[0]}}</td>
                <td class="text-center meio">{{meta(dt, 'manha').split('#')[1]}}</td>
                <td class="text-center meio">{{meta(dt, 'tarde').split('#')[0]}}</td>
                <td class="text-center meio">{{meta(dt, 'tarde').split('#')[1]}}</td>
                <td class="text-center meio">{{calcularHorasFN(dt)}} h</td>
                <td v-if="!user.monitores_fixos" class="meio text-center meio text-succes">{{calcularValorFN(dt)}} €</td>
                <td v-if="!user.monitores_fixos" class="meio text-center meio"><div :style="meta(dt, 'PP') ? '' : 'visibility: hidden;'" v-html="meta(dt, 'PP') ? check() : ''"></div></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-center meio" colspan="5">Total</td>
                <th class="text-center meio">{{horas}} h</th>
                <th v-if="!user.monitores_fixos" class="text-center meio">{{total}} €</th>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class=" p-5" v-if="resumo === 2" style="width:100%">
        <h3 class="ml-20">Legendas e Informação</h3>
        <div class="row">
          <div class="col-lg-2 col-xs-12 col-md-12"></div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" style="font-size: 0.9rem;">
            Informação:<br />Disponibilidade da semana de {{disp1}} >> já terminou.<br />Disponibilidade da semana de {{disp2}} >> termina dia {{disp22}}.
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" style="font-size: 0.9rem;">
            Legenda:
            <div style="clear:both; border: solid 1px #333; margin-top:3px;" class="alert p-5 mb-0 verdeX">- Horário de trabalho definido e validado<br /><span class="text-danger">- Horário de trabalho alterado</span></div>
            <div style="clear:both; border: solid 1px #333; margin-top:3px;color:#333" class="alert p-5 mb-0 alert-danger">- Contactar a gerência para definir melhor o horário de trabalho</div>
            <div style="clear:both; border: solid 1px #333; margin-top:3px;" class="alert p-5 amareloX">- Pedidos de disponibilidade fora do tempo definido</div>
          </div>
        </div>
      </div>

      <div class="p-5" v-if="!resumo" style="width:100%">
        <h3 class="ml-20">Gestão de Horário de Trabalho</h3>

        <div class="col-xs-12 col-sm-6 col-lg-6 col-md-6" v-if="user.balizaslandia && user.balizaslandia_oaz">
          <div class="radio-custom radio-primary mr-20 float-left">
            <input @change="formUrl(); alocados = 0;" class="foco" type="radio" name="pesaco" id="balizaslandia123" v-model="espaco" value="smf" checked>
            <label for="balizaslandia123">Balizaslandia Sº Mº Feira</label>
          </div>
          <div class="radio-custom radio-primary mr-20 float-left">
            <input @change="formUrl(); alocados = 0;" class="foco" type="radio" name="pesaco" id="balizaslandia_oaz" v-model="espaco" value="oaz">
            <label for="balizaslandia_oaz">Balizaslandia O. Azeméis</label>
          </div>
        </div>

        <button v-if="alocados" @click="alocados = 0" class="float-right btn btn-danger mr-20">Sair</button>
        <button v-else @click="alocados = 1" class="float-right btn btn-success mr-20">Ver dias alocados</button>

        <div class="table-responsive cinzento1 pt-10" style="clear:both;">
          <table id="tabelaFixa" class="table balizas monitor table-striped table-condensed" style="width:auto;margin: 0 auto;">
            <thead>
              <tr>
                <th style="top: 0px;width:150px;" class="meio azul text-center">Data</th>
                <th style="top: 0px;width:100px;" class="meio azul text-center">Manhã</th>
                <th style="top: 0px;width:100px;" class="meio azul text-center">Tarde</th>
                <th style="top: 0px;width:100px;" class="meio azul text-center">Indisp.</th>
                <th style="top: 0px;width:250px;" class="meio azul text-center">Observações Monitor &amp; Gerência</th>
                <th style="top: 0px;width:150px;" class="meio azul text-center">Estado</th>
                <th v-if="!user.monitores_fixos" style="top: 0px;width:80px;" class="meio azul text-center">Valor (€)</th>
                <!--<th style="top: 0px;width:100px;" class="meio azul text-center">Local</th>-->
              </tr>
            </thead>
            <tbody style="color:#000;">
              <tr v-for="(dt, index) in res1" :key="index" :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1}" v-show="(meta(dt, 'validado') == 1 || meta(dt, 'Donuts')) && alocados || !alocados">
                <td class="meio slot">{{dt.data}} - {{dias[semana + index]}}</td>

                <td :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'meio text-center': 1}">
                  <div class="checkbox-custom checkbox-primary m-0" v-if="!meta(dt, 'validado') && !meta(dt, 'Donuts')">
                    <input v-if="dt.id" type="checkbox" :id="'gfdsgsfgsgs-' + index" v-model="dt.manha" disabled>
                    <input v-else type="checkbox" :id="'gfdsgsfgsgs-' + index" v-model="dt.manha" @click="dt.up = 1">
                    <label :for="'gfdsgsfgsgs-' + index"></label>
                  </div>
                  <div :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'text-danger': meta(dt, 'Donuts')  }" v-if="meta(dt, 'manha') && meta(dt, 'Crossaints')">{{meta(dt, 'manha').split('#')[0]}} - {{meta(dt, 'manha').split('#')[1]}}</div>
                </td>

                <td :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'meio text-center': 1}">
                  <div class="checkbox-custom checkbox-primary m-0" v-if="!meta(dt, 'validado') && !meta(dt, 'Donuts')">
                    <input v-if="dt.id" type="checkbox" :id="'gfdsgsfgsgs324-' + index" v-model="dt.tarde" disabled>
                    <input v-else type="checkbox" :id="'gfdsgsfgsgs324-' + index" v-model="dt.tarde" @click="dt.up = 1">
                    <label :for="'gfdsgsfgsgs324-' + index"></label>
                  </div>
                  <div :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'text-danger': meta(dt, 'Donuts')  }" v-if="meta(dt, 'tarde') && meta(dt, 'Crossaints')">{{meta(dt, 'tarde').split('#')[0]}} - {{meta(dt, 'tarde').split('#')[1]}}</div>
                </td>

                <td :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'meio': 1}">
                  <div class="checkbox-custom checkbox-primary m-0">
                    <input v-if="dt.id" type="checkbox" :id="'fdsfsfdsfsd4343dd-' + index" v-model="dt.indisp" disabled>
                    <input v-else type="checkbox" :id="'fdsfsfdsfsd4343dd-' + index" v-model="dt.indisp" @click="dt.up = 1">
                    <label :for="'fdsfsfdsfsd4343dd-' + index"></label>
                  </div>
                </td>
                <td :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'meio': 1}">
                  <textarea row="1" type="text" class="form-control" placeholder="" v-if="!dt.id" v-model="dt.observation" @change="dt.up = 1"></textarea>
                  <span v-else v-html="dt.observation"></span> / {{meta(dt, 'Obvs')}}
                </td>
                <td :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'meio text-center': 1}">
                  <a v-if="!dt.id && dt.up" @click="submit(dt);dt.up = 0;" class="btn btn-block btn-primary" href="javascript:void(0)">Submeter</a>
                  <!--disp11 < new Date(dt.event_at)-->
                  <a v-if="dt.id && !meta(dt, 'validado') && !meta(dt, 'Crossaints') && !meta(dt, 'Donuts')" @click="apagar(dt, index)" class="btn btn-block btn-default" href="javascript:void(0)">Editar</a>
                  <div v-if="!meta(dt, 'validado') && !meta(dt, 'Donuts') && meta(dt, 'Crossaints')">
                    <a @click="okFN(dt, -1)" class="btn-sm btn ml-5 mr-5 btn-danger" href="javascript:void(0)">NOT OK</a>
                    <a @click="okFN(dt, 1)" class="btn-sm btn ml-5 mr-5 btn-success" href="javascript:void(0)">OK</a>
                  </div>

                  <!--<span v-if="dt.validacao" class="text-danger">Passou o prazo, a gerência irá verificar</span>-->
                  <span v-if="dt.id && !meta(dt, 'validado') && !meta(dt, 'Crossaints') && !meta(dt, 'Donuts') && !meta(dt, 'Nuggets')" class="text-warning">Aguarda validação</span>
                  <div v-if="meta(dt, 'validado') || meta(dt, 'Donuts')">
                    <span v-if="meta(dt, 'validado') == -1 && !meta(dt, 'Donuts') ">Contactar a gerência</span>
                    <span :class="{'verdeX': meta(dt, 'validado') == 1 && meta(dt, 'Crossaints') || meta(dt, 'Donuts'), 'amareloX': new Date(dt.event_at) < validacao, 'vermelhoX': meta(dt, 'validado') == -1, 'text-success': !meta(dt, 'Donuts') , 'text-danger': meta(dt, 'Donuts') }" v-if="meta(dt, 'validado') == 1 || meta(dt, 'Donuts')">Alocado</span>
                  </div>
                </td>
                <template v-if="!user.monitores_fixos">
                  <td v-if="dt.id" :class="{'meio text-center slot': 1}"><span v-if="meta(dt, 'validado') == 1 || meta(dt, 'Donuts')">{{calcularValorFN(dt)}} €</span></td>
                  <td v-else :class="{'meio slot': 1}"></td>
                </template>
                <!--<td :class="{'meio slot': 1}"><span v-if="meta(dt, 'validado') == 1 || meta(dt, 'Donuts')">{{dt.n}}</span></td>-->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchEvent, createEventClient, patchEventClient, deleteEvent, patchUser, empresaUsers } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { commonFunctionsMixin } from '../filters/funcoes'

export default {
  name: 'pageMonitor',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      year: '',
      month: '',
      day: '',
      year1: '',
      month1: '',
      day1: '',
      res1: [],
      res2: [],
      horas: 0,
      total: 0,
      sitio: [ 'Balcão', 'Balcão', 'Lanche', 'Lanche', 'Lanche', 'Cacifos', 'Cacifos/Lanche', 'Parque', 'Parque', 'Parque', 'Parque', 'Parque', 'Bar', 'A Definir', 'A Definir', 'A Definir' ],
      resumo: 0,
      semana: 0,
      dias: ['Domingo', 'Segunda', 'Terça', 'Quarta', ' Quinta', 'Sexta', 'Sábado', 'Domingo', 'Segunda', 'Terça', 'Quarta', ' Quinta', 'Sexta', 'Sábado', 'Domingo', 'Segunda', 'Terça', 'Quarta', ' Quinta', 'Sexta', 'Sábado', 'Domingo','Segunda', 'Terça', 'Quarta', ' Quinta', 'Sexta', 'Sábado', 'Domingo'],
      validacao: '',
      passado: 0,
      alocados: 0,
      disp1: '',
      disp11: '',
      disp2: '',
      disp22: '',
      menu: 0,
      user: {},
      espaco: ''
    }
  },
  mounted () {
    this.SET_LOAD(true)
    let a = new Date()
    let b = new Date(a.setDate(a.getDate() - a.getDay() + 2))
    if (new Date() < new Date(b.getFullYear() + '-' + ('0' + (1 + b.getMonth())).slice(-2) + '-' + ('0' + b.getDate()).slice(-2) + ' 12:00:00')) {
      this.validacao = new Date(a.setDate(a.getDate() - a.getDay() + 7))
    } else {
      this.validacao = new Date(a.setDate(a.getDate() - a.getDay() + 14))
    }
    empresaUsers(this.axios).then((res) => {
      this.user = res.data.filter(a => a.email === this.$store.state.user.email)[0]
      console.log(this.user)
      if (this.user.balizaslandia) {
        this.espaco = 'smf'
      } else {
        if (this.user.balizaslandia_oaz) {
          this.espaco = 'oaz'
        } else {
          if (this.user.balizas) {
            this.espaco = 'balizas'
          } else {
            this.espaco = ''
          }
        }
      }
      this.formUrl()
    }, () => {})
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    semanaFN (dt) {
      return this.dias[new Date(dt.event_at).getDay()]
    },
    formUrl (passado) {
      if(!this.menu) {
        this.SET_LOAD(false)
        return
      }
      this.SET_LOAD(true)
      let a = new Date()
      let x
      let cal = []
      // a = new Date(a.setDate(a.getDate() - a.getDay() + 1))
      if (this.espaco === 'smf' && this.user.balizaslandia) {
        cal = [66]
      }
      if (this.espaco === 'oaz' && this.user.balizaslandia_oaz) {
        cal = [109]
      }
      if (this.espaco === 'balizas' && this.user.balizas) {
        cal = [93]
      }

      if (passado) {
        let kkkk = 0
        if (passado === 2) {
          kkkk = 1
        }
        x = new Date(a.getFullYear(), a.getMonth() + 1 - kkkk, 0)
        a = new Date(a.getFullYear(), a.getMonth() - kkkk, 1)
        this.month1 = ('0' + (1 + x.getMonth())).slice(-2)
        this.year1 = x.getFullYear()
        this.day1 = ('0' + x.getDate()).slice(-2)

        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)

        this.res1 = [{ data: a.getDate() + ' ' + this.$t('meses.' + (1 + a.getMonth())).substring(0, 3), tarde: 0, manha: 0, indisp: 0,  calendar_id: cal, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: a.getFullYear() + '-' + ('0' + (1 + a.getMonth())).slice(-2) + '-' + ('0' + a.getDate()).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' }]
        while(new Date(a) < new Date(x)) {
          a.setDate(a.getDate() + 1)
          this.res1.push({ data: a.getDate() + ' ' + this.$t('meses.' + (1 + a.getMonth())).substring(0, 3), tarde: 0, manha: 0, indisp: 0,  calendar_id: cal, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: a.getFullYear() + '-' + ('0' + (1 + a.getMonth())).slice(-2) + '-' + ('0' + a.getDate()).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' })
        }
      } else {
        x = new Date(this.validacao)
        x.setDate(x.getDate() + 14)
        this.semana = a.getDay()
        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)

        // let i = 0
        this.res1 = [{ data: a.getDate() + ' ' + this.$t('meses.' + (1 + a.getMonth())).substring(0, 3), tarde: 0, manha: 0, indisp: 0,  calendar_id: cal, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: a.getFullYear() + '-' + ('0' + (1 + a.getMonth())).slice(-2) + '-' + ('0' + a.getDate()).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' }]
        while(new Date(a) < new Date(x)) {
          a.setDate(a.getDate() + 1)
          this.res1.push({ data: a.getDate() + ' ' + this.$t('meses.' + (1 + a.getMonth())).substring(0, 3), tarde: 0, manha: 0, indisp: 0,  calendar_id: cal, repeat: 'no', client_id: 0, pack_id: '', price: '', event_at: a.getFullYear() + '-' + ('0' + (1 + a.getMonth())).slice(-2) + '-' + ('0' + a.getDate()).slice(-2), duration_minutes: 30, title: 'Monitor', color: '#009CE0', observation: '' })
        }
        this.month1 = ('0' + (1 + a.getMonth())).slice(-2)
        this.year1 = a.getFullYear()
        this.day1 = ('0' + a.getDate()).slice(-2)

        a = new Date()
        let dd
        let xxx = a.getDay()
        if (xxx === 0) {
          dd = new Date(a.setDate(a.getDate() - xxx))
        } else {
          if (xxx === 1) {
            dd = new Date(a.setDate(a.getDate() - xxx + 1))
          } else {
            dd = new Date(a.setDate(a.getDate() - xxx + 8))
          }
        }
        this.disp22 = dd.getDate() + ' ' + this.$t('meses.' + (1 + dd.getMonth())).substring(0, 3)
        this.disp1 = this.disp22 + ' a '
        this.disp11 = new Date(dd)
        dd.setDate(dd.getDate() + 6)
        this.disp1 += dd.getDate() + ' ' + this.$t('meses.' + (1 + dd.getMonth())).substring(0, 3)

        dd.setDate(dd.getDate() + 1)
        this.disp2 = dd.getDate() + ' ' + this.$t('meses.' + (1 + dd.getMonth())).substring(0, 3) + ' a '
        dd.setDate(dd.getDate() + 6)
        this.disp2 += dd.getDate() + ' ' + this.$t('meses.' + (1 + dd.getMonth())).substring(0, 3)
      }

      searchEvent(this.axios, cal, '', '', '', this.year1 + '-' + this.month1 + '-' + this.day1, this.year + '-' + this.month + '-' + this.day, '', '').then((res) => {
        // let x = res.data.filter(a => a.eventable.email === this.$store.state.user.email)
        let i = 0
        let k
        this.res2 = []
        let horas = 0
        let total = 0
        while (this.res1[i]) {
          x = res.data.find(a => a.eventable.email === this.$store.state.user.email && a.event_at.split(' ')[0] === this.res1[i].event_at.split(' ')[0])
          if (!x) {
            ++i
            continue
          }
          x.data = parseInt(x.event_at.substring(8, 10)) + ' ' + this.$t('meses.' + parseInt(x.event_at.substring(5, 7))).substring(0, 3)
          if (this.espaco === 'balizas') {
            a =  new Date(new Date((parseInt(this.meta(x, 'Anos 3') < 5) ? '2022-01-02 ' : '2022-01-01 ') + this.meta(x, 'Anos 3')) - new Date('2022-01-01 ' + this.meta(x, 'Anos 2')))
            x.horas = a.getUTCHours() + a.getUTCMinutes() / 60 || 0
            horas += x.horas

            total += (this.meta(x, 'valor recebido') > 0 ? parseFloat(this.meta(x, 'valor recebido')) * x.horas : 0)
            this.res2.push(x)

          } else {
            x.n = this.meta(x, 'pinhata_tema1') || this.sitio[x.Sitio]
            if (x.event_at.split(' ')[1] === '09:00:00') {
              x.manha = 1
            }
            if (x.event_at.split(' ')[1] === '13:00:00' || parseInt(x.duration_minutes) === 90) {
              x.tarde = 1
            }
            if (this.meta(x, 'Nuggets')) {
              x.indisp = 1
            }
            x.faltou = this.meta(x, 'Donuts')
            x.enviado = this.meta(x, 'Crossaints')

            this.res1[i] = Object.assign({}, this.res1[i], x)

            if (passado && (this.meta(this.res1[i], 'validado') > 0 || this.meta(this.res1[i], 'Donuts'))) {
              k = 0
              this.res2.push(this.res1[i])
              if (this.meta(this.res1[i], 'manha').indexOf(':') > 0) {
                a = new Date(new Date('2022-01-01 ' + this.meta(this.res1[i], 'manha').split('#')[1]) - new Date('2022-01-01 ' + this.meta(this.res1[i], 'manha').split('#')[0]))
                k = a.getUTCHours() + a.getUTCMinutes() / 60 || 0
                horas += k
              }
              if (this.meta(this.res1[i], 'tarde').indexOf(':') > 0) {
                a = new Date(new Date('2022-01-01 ' + this.meta(this.res1[i], 'tarde').split('#')[1]) - new Date('2022-01-01 ' + this.meta(this.res1[i], 'tarde').split('#')[0]))
                k += a.getUTCHours() + a.getUTCMinutes() / 60 || 0
                horas += a.getUTCHours() + a.getUTCMinutes() / 60 || 0
              }
              /*
              if (this.meta(this.res1[i], 'PP')) {
                horas = 0
                k = 0
              }
              */
              total += k * (this.meta(this.res1[i], 'valor recebido') > 0 ? this.meta(this.res1[i], 'valor recebido') : 0)
            }
          }
          ++i
        }
        this.horas = horas
        this.total = total
        this.$forceUpdate()
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    submit (x) {
      let dt = Object.assign(x);
      dt.contact = {
        name: this.$store.state.user.name,
        email: this.$store.state.user.email
      }
      if (dt.indisp) {
        // indisponivel
        dt.meta = {
          'Nuggets': 1
        }
        dt.event_at += ' 01:00'
      } else {
        if (dt.manha) {
          dt.event_at += ' 09:00'
        }
        if (!dt.manha && dt.tarde) {
          dt.event_at += ' 13:00'
        }
        if (dt.manha && dt.tarde) {
          dt.duration_minutes = 90
        }
        if (!dt.manha && !dt.tarde) {
          this.SET_TOAST({ msg: 'Apenas deve marcar os dias que tem disponibilidade', type: 'error' })
          return
        }
      }
      this.SET_LOAD(true)
      dt.calendar_id = 66
      if (this.espaco === 'oaz' && this.user.balizaslandia_oaz) {
        dt.calendar_id = 109
      }

      // obs_monitor - valor recebido
      createEventClient(this.axios, dt).then(() => {
        // dt.contact.id = res.data.eventable.id
        // patchClient(this.axios, dt.contact).then(() => {})
        // x.id = res.data.id
        this.formUrl()
        // let a = new Date()
        // x.validacao = new Date(a.setDate(a.getDate() - a.getDay() + 14)) > new Date(res.data.event_at)
        // x.validacao = new Date(a.setDate(a.getDate() - a.getDay() + 1)) > new Date(res.data.event_at)
        this.$forceUpdate()
        this.SET_TOAST({ msg: this.$t('Horário marcado com sucesso') })
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    okFN (x, ok) {
      let dt = Object.assign(x);
      let i = dt.meta.length
      let meta = {}
      while (--i >= 0) {
        if (dt.meta[i].meta_value) {
          meta[dt.meta[i].meta_key] = dt.meta[i].meta_value
        }
      }
      meta['validado'] = ok
      dt.meta = meta
      this.SET_LOAD(true)
      dt.event_at = dt.event_at.substring(0, 16)
      patchEventClient(this.axios, dt).then(() => {
        this.formUrl()
        this.SET_TOAST({ msg: this.$t('Enviado com sucesso.') })
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    calcularValorFN (dt) {
      let valor = 0
      let a
      if (this.meta(dt, 'manha').indexOf(':') > 0) {
        a = new Date(new Date('2022-01-01 ' + this.meta(dt, 'manha').split('#')[1]) - new Date('2022-01-01 ' + this.meta(dt, 'manha').split('#')[0]))
        valor = a.getUTCHours() + a.getUTCMinutes() / 60
      }
      if (this.meta(dt, 'tarde').indexOf(':') > 0) {
        a = new Date(new Date('2022-01-01 ' + this.meta(dt, 'tarde').split('#')[1]) - new Date('2022-01-01 ' + this.meta(dt, 'tarde').split('#')[0]))
        valor += a.getUTCHours() + a.getUTCMinutes() / 60
      }
      return valor > 0 ? valor * (this.meta(dt, 'valor recebido') > 0 ? this.meta(dt, 'valor recebido') : 0) : 0
    },
    calcularHorasFN (dt) {
      let valor = 0
      let a
      if (this.meta(dt, 'manha').indexOf(':') > 0) {
        a = new Date(new Date('2022-01-01 ' + this.meta(dt, 'manha').split('#')[1]) - new Date('2022-01-01 ' + this.meta(dt, 'manha').split('#')[0]))
        valor = a.getUTCHours() + a.getUTCMinutes() / 60
      }
      if (this.meta(dt, 'tarde').indexOf(':') > 0) {
        a = new Date(new Date('2022-01-01 ' + this.meta(dt, 'tarde').split('#')[1]) - new Date('2022-01-01 ' + this.meta(dt, 'tarde').split('#')[0]))
        valor += a.getUTCHours() + a.getUTCMinutes() / 60
      }
      return valor || 0
    },
    apagar (dt) {
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i> Tem a certeza que pretende apagar?',
        // text: '<div class="text-uppercase text-center">NÃO É POSSÍVEL APAGAR MARCAÇÕES TERMINADAS</div>',
        buttons: [
          {
            title: this.$t('cancelar'),
            handler: () => {
              this.$modal.hide('dialog')
            },
            class: 'btn btn-default'
          },
          {
            title: this.$t('confirmar'),
            default: true,
            handler: () => {
              this.SET_LOAD(true)
              deleteEvent(this.axios, dt.id).then(() => {
                this.$modal.hide('dialog')
                this.formUrl()
              }, () => {
                this.$modal.hide('dialog')
                this.SET_TOAST({ msg: 'Não é possível apagar.', type: 'error' })
              })
            },
            class: 'btn btn-danger'
          }
        ]
      })
    },
    resumoFN () {
      this.menu = 1
      this.resumo = 1
      this.passado = 0
      this.formUrl(this.resumo)
    },
    mudarSenha () {
      this.$swal({
        title: 'Alterar Senha',
        input: 'password',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
      }).then((res) => {
        if ((res.value || '').length >= 6) {
          this.SET_LOAD(true)
          patchUser(this.axios, { password: res.value, name: this.$store.state.user.name, id: this.$store.state.user.id, email: this.$store.state.user.email, permissions: { is_admin: 0, see_just_own_data: 0, is_monitor: 1 } }).then(() => {
           this.SET_TOAST({ msg: this.$t('Senha atualizada com sucesso.') })
          }, () => {
            this.SET_TOAST({ msg: this.$t('Não foi possível mudar a senha.'), type: 'error' })
          })
        } else {
          if (res.value) {
            this.SET_TOAST({ msg: this.$t('Não foi possível mudar a senha, porque deve conter 6 caracteres no mínimo.'), type: 'error' })
          }
        }
      })
    }
  }
}
</script>
