import _ from 'lodash'

export const commonFunctionsMixin = {
  methods: {
    lancheFN (a) {
      if (this.$store.state.balizaslandia === 'smf') {
        switch (a) {
          case '09:00:00':
            return '10:00'
          case '09:01:00':
            return '10:15'
          case '09:30:00':
            return '10:30'
          case '09:45:00':
            return '10:45'
          case '10:00:00':
            return '11:00'
          case '10:30:00':
            return '11:30'
          case '10:45:00':
            return '11:45'
          case '11:30:00':
            return '12:00'
          case '11:31:00':
            return '12:15'
          case '12:00:00':
            return '12:30'
          case '12:30:00':
            return '13:00'
          case '12:45:00':
            return '13:15'
          case '14:00:00':
            return '15:00'
          case '14:01:00':
            return '15:15'
          case '14:15:00':
            return '15:30'
          case '14:30:00':
            return '15:45'
          case '15:30:00':
            return '16:45'
          case '17:00:00':
            return '17:30'
          case '17:15:00':
            return '18:00'
          case '17:30:00':
            return '18:30'
          case '18:00:00':
            return '19:00'
          case '18:01:00':
            return '19:10'
        }
      }
      if (this.$store.state.balizaslandia === 'oaz') {
        switch (a) {
          case '09:00:00':
            return '10:00'
          case '09:15:00':
            return '10:15'
          case '09:30:00':
            return '10:30'
          case '09:45:00':
            return '10:45'
          case '10:00:00':
            return '11:00'
          case '10:30:00':
            return '11:30'
          case '10:45:00':
            return '11:45'
          case '11:30:00':
            return '12:00'
          case '11:45:00':
            return '12:15'
          case '12:00:00':
            return '12:30'
          case '12:30:00':
            return '13:00'
          case '12:45:00':
            return '13:15'
          case '14:00:00':
            return '15:00'
          case '14:15:00':
            return '15:30'
          case '14:30:00':
            return '15:45'
          case '15:00:00':
            return '16:00'
          case '15:30:00':
            return '16:45'
          case '17:00:00':
            return '17:30'
          case '17:15:00':
            return '18:00'
          case '17:30:00':
            return '18:30'
          case '17:45:00':
            return '19:00'
          case '18:00:00':
            return '19:10'
        }
      }
    },
    dispositivoFN (a) {
      if (this.$store.state.balizaslandia === 'smf') {
        switch (a) {
          case '09:00:00':
            return 1
          // case '09:01:00':
          // return 2
          case '09:30:00':
            return 2
          case '09:45:00':
            return 3
          case '10:00:00':
            return 4
          case '10:30:00':
            return 5
          case '10:45:00':
            return 6
          case '11:30:00':
            return 7
          case '11:31:00':
            return 8
          case '12:00:00':
            return 9
          case '12:30:00':
            return 10
          case '12:45:00':
            return 11
          case '14:00:00':
            return 12
          case '14:01:00':
            return 13
          case '14:15:00':
            return 14
          case '14:30:00':
            return 15
          case '15:30:00':
            return 16
          case '17:00:00':
            return 17
          case '17:15:00':
            return 18
          case '17:30:00':
            return 19
          case '18:00:00':
            return 20
          case '18:01:00':
            return 21
        }
      }
      if (this.$store.state.balizaslandia === 'oaz') {
        switch (a) {
          // case '09:00:00':
          //  return 1
          case '09:15:00':
            return 1
          case '09:30:00':
            return 2
          case '09:45:00':
            return 3
          case '10:00:00':
            return 4
          case '10:30:00':
            return 5
          case '10:45:00':
            return 6
          case '11:30:00':
            return 7
          case '11:45:00':
            return 8
          case '12:00:00':
            return 9
          case '12:30:00':
            return 10
          case '12:45:00':
            return 11
          case '14:00:00':
            return 12
          case '14:15:00':
            return 13
          case '14:30:00':
            return 14
          case '15:00:00':
            return 15
          case '15:30:00':
            return 16
          case '17:00:00':
            return 17
          case '17:15:00':
            return 18
          case '17:30:00':
            return 19
          case '17:45:00':
            return 20
          case '18:00:00':
            return 21
        }
      }
    },
    coresFN (a) {
      if (this.$store.state.balizaslandia === 'smf') {
        switch (a) {
          case '09:00:00':
            return 'Branco'
          case '09:01:00':
            return 'Laranja'
          case '09:30:00':
            return 'Preto'
          case '09:45:00':
            return 'Rosa'
          case '10:00:00':
            return 'Amarelo'
          case '10:30:00':
            return 'Azul'
          case '10:45:00':
            return 'Branco'
          case '11:30:00':
            return 'Vermelho'
          case '11:31:00':
            return 'Verde'
          case '12:00:00':
            return 'Castanho'
          case '12:30:00':
            return 'Roxo'
          case '12:45:00':
            return 'Cinzento'
          case '14:00:00':
            return 'Branco'
          case '14:01:00':
            return 'Laranja'
          case '14:15:00':
            return 'Preto'
          case '14:30:00':
            return 'Rosa'
          case '15:30:00':
            return 'Amarelo'
          case '17:00:00':
            return 'Azul'
          case '17:15:00':
            return 'Vermelho'
          case '17:30:00':
            return 'Verde'
          case '18:00:00':
            return 'Castanho'
          case '18:01:00':
            return 'Roxo'
        }
      }
      if (this.$store.state.balizaslandia === 'oaz') {
        switch (a) {
          case '09:00:00':
            return 'Branco'
          case '09:15:00':
            return 'Laranja'
          case '09:30:00':
            return 'Preto'
          case '09:45:00':
            return 'Rosa'
          case '10:00:00':
            return 'Amarelo'
          case '10:30:00':
            return 'Azul'
          case '11:30:00':
            return 'Vermelho'
          case '10:45:00':
            return 'Branco'
          case '11:45:00':
            return 'Verde'
          case '12:00:00':
            return 'Castanho'
          case '12:30:00':
            return 'Roxo'
          case '12:45:00':
            return 'Cinzento'
          case '14:00:00':
            return 'Branco'
          case '14:15:00':
            return 'Laranja'
          case '14:30:00':
            return 'Preto'
          case '15:00:00':
            return 'Rosa'
          case '15:30:00':
            return 'Amarelo'
          case '17:00:00':
            return 'Azul'
          case '17:15:00':
            return 'Vermelho'
          case '17:30:00':
            return 'Verde'
          case '17:45:00':
            return 'Castanho'
          case '18:00:00':
            return 'Roxo'
        }
      }
    },
    printTable () {
      let doc1 = document.getElementById('talaoPrint').contentWindow.document
      doc1.open()
      doc1.write('<html><head><style>* {margin:0px;padding:0px;font-family: "Roboto";} table, td, th {border: solid 1px #444;} .linha td {border-bottom: solid 3px #000 !important}</style></head><body>' + document.getElementById('ffsdfsddfmdfsPrint').innerHTML + '</body></html>')
      doc1.close()
      _.delay(function () {
        document.getElementById('talaoPrint').contentWindow.focus()
        document.getElementById('talaoPrint').contentWindow.print()
      }, 500)
    },

    meta (dt, campo, campo1, virgula, foot) {
      if (!dt) {
        return ''
      }
      if (dt.id) {
        let a = dt.meta.find(a => a.meta_key === campo)
        if (a) {
          if (foot === 'foot') {
            switch (parseInt(a.meta_value)) {
              case 1:
                return 'Jogo Fixo 42€'
              case 2:
                return 'Jogo Normal 48€'
              case 3:
                return 'Jogo LowCost Fixo 30€'
              case 4:
                return 'Jogo LowCost Normal 35€'
              case 5:
                return 'Festa BalizasLandia'
            }
            return ''
          }
          if (a.meta_key === 'menu') {
            switch (a.meta_value) {
              case 6:
                return 'Normal 11€'
              case 10:
                return 'Normal 12€'
              case 1:
                return 'Premium 18€'
              case 11:
                return 'Premium 19€'
              case 2:
                return 'Normal Almoço/Jantar 14€'
              case 12:
                return 'Normal Almoço/Jantar 15€'
              case 3:
                return 'Normal 13€'
              case 4:
                return 'Premium 20€'
              case 5:
                return 'Normal Almoço/Jantar 16€'
              case 7:
                return 'Centro Estudos'
              case 8:
                return 'Premium Almoço/Jantar 22€'
              case 9:
                return 'Premium Almoço/Jantar 23€'
              case 13:
                return 'Normal 14€'
              case 15:
                return 'Normal Almoço/Jantar 16€'
              case 14:
                return 'Normal Almoço/Jantar 17€'
              case 16:
                return 'Normal 13€'
              case 17:
                return 'Normal 14€'
              case 18:
                return 'Normal Almoço/Jantar 17€'
              case 19:
                return 'Normal 15€'
              case 20:
                return 'Normal Almoço/Jantar 18€'
            }
          }
          if (!a.meta_value) {
            return ''
          }
          if ((a.meta_value === 'true' || a.meta_value === '1' || a.meta_value === 1) && a.meta_key !== 'Caução Paga' && a.meta_key !== 'validado' && a.meta_key !== 'Sitio' && a.meta_key !== 'tempo' && a.meta_key !== 'Total1' && a.meta_key !== 'Total2' && a.meta_key !== 'Vales' && a.meta_key !== 'valor recebido' && a.meta_key !== 'Total Crianças' && a.meta_key !== 'Anos' && a.meta_key !== 'Anos 2' && a.meta_key !== 'Anos 3' && a.meta_key !== 'Anos 4' && a.meta_key !== 'Meias' && a.meta_key !== 'bolo_qtd' && a.meta_key !== 'Adulto') {
            if (campo1) {
              let b = dt.meta.find(x => x.meta_key === campo1)
              if (b) {
                if (b.meta_value) {
                  return a.meta_key + ' -> ' + b.meta_value + (virgula && a.meta_value ? ' / ' : '')
                } else {
                  return a.meta_key
                }
              }
            }
            return a.meta_key + (virgula && a.meta_key ? ' / ' : '')
          } else {
            if (virgula === 3) {
              return a.meta_value + (virgula && a.meta_value ? ' # ' : '')
            }
            if (a.meta_value === '0' || a.meta_value === 0) {
              return ''
            }
            return a.meta_value + (virgula && a.meta_value ? ' / ' : '')
          }
        }
      }
      return ''
    },
    check () {
      return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#25AE88;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
    },
    check1 () {
      return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#eeee00;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="  38,15 22,33 12,25 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
    },
    close () {
      return '<svg style="width:25px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><circle style="fill:#D75A4A;" cx="25" cy="25" r="25"/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,34 25,25 34,16   "/><polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,16 25,25 34,34   "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>'
    },
    calcManha (dt) {
      let a = new Date(new Date('2022-01-01 ' + dt.manha_fim) - new Date('2022-01-01 ' + dt.manha_inicio))
      a = a.getUTCHours() + a.getUTCMinutes() / 60
      let b = new Date(new Date('2022-01-01 ' + dt.manha_inicio) - new Date('2022-01-01 08:00:00'))
      b = b.getUTCHours() + b.getUTCMinutes() / 60
      let x = a * 100 / 7
      let y = b > 0 ? b * 100 / 7 : 0
      if (dt.enviado && dt.validado > 0 || dt.faltou) {
        return 'height: 23px;background: green;width: ' + x + '%;margin-left: ' + y + '%';
      }
      if (dt.enviado) {
        return 'height: 23px;background: yellow;width: ' + x + '%;margin-left: ' + y + '%';
      }
      return 'height: 23px;background: #ddd;width: ' + x + '%;margin-left: ' + y + '%';
    },
    calcTarde (dt) {
      let a = new Date(new Date('2022-01-01 ' + dt.tarde_fim) - new Date('2022-01-01 ' + dt.tarde_inicio))
      a = a.getUTCHours() + a.getUTCMinutes() / 60
      let b = new Date(new Date('2022-01-01 ' + dt.tarde_inicio) - new Date('2022-01-01 12:00:00'))
      b = b.getUTCHours() + b.getUTCMinutes() / 60
      let x = a * 100 / 10
      let y = b > 0 ? b * 100 / 10 : 0
      if (dt.enviado && dt.validado > 0 || dt.faltou) {
        return 'height: 23px;background: green;width: ' + x + '%;margin-left: ' + y + '%';
      }
      if (dt.enviado) {
        return 'height: 23px;background: yellow;width: ' + x + '%;margin-left: ' + y + '%';
      }
      return 'height: 23px;background: #ddd;width: ' + x + '%;margin-left: ' + y + '%';
    },
    logout () {
      this.$auth.logout({
        makeRequest: false,
        params: {},
        redirect: '/login',
        success: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
          // window.location.assign('/')
          // window.location = window.location.protocol + '//' + window.location.hostname
        },
        error: function () {
          this.$auth.token('bearer', '')
          this.$router.replace('/')
        }
      })
    },
    corFN (a) {
      switch (parseInt(a)) {
        case 2:
          return 'c-amarelo'
        case 3:
          return 'c-verde'
      }
      return ''
    },
    metaKey (key) {
      return this.$store.state.metaeventos.findIndex(a => a.meta_key === key)
    },

    boloTemaFN (dt, op) {
      switch (this.meta(dt, 'Bolo')) {
        case 'Bolo 1Kg':
        case 'Bolo 2Kg':
        case 'Bolo Artístico':
          if (this.meta(dt, 'bolo_tema1')) {
            return ((this.meta(dt, 'bolo_qtd') || 1) + ' x ' + this.meta(dt, 'Bolo') + ' -> ' + this.meta(dt, 'bolo_tema1')) + op
          }
          return ((this.meta(dt, 'bolo_qtd') || 1) + ' x ' + this.meta(dt, 'Bolo') + ' -> Tema a confirmar') + op
      }
      return (this.meta(dt, 'Bolo') + (this.meta(dt, 'bolo_tema1') ? ' -> ' + this.meta(dt, 'bolo_tema1') : '')) + op
    },
    linhasFN (dt, index) {
      let t = this.res.length - 1
      if (index < (t - 1)) {
        if (this.res[index + 1].event_at === dt.event_at && this.res[index + 2].event_at === dt.event_at) {
          this.res[index + 1].ocultar = 1
          this.res[index + 2].ocultar = 1
          return 3
        }
      }
      if (index < t) {
        if (this.res[index + 1].event_at === dt.event_at) {
          this.res[index + 1].ocultar = 1
          return 2
        }
      }
      return 1
    },
  }
}