<template>
  <div class="row pr cinzento m-0" style="min-height: 100vh;">
    <div class="text-center balizas-calendario cinzento">
      <a @click="SET_BALIZAS('POS');$router.push('/');" class="mao">
        <img :src="$store.state.balizaslandia === 'smf' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:95%; max-width: 230px;" /></a>

      <div>
        <div class="p-10"><button class="btn btn-success btn-block" @click="onDayClick">Hoje</button></div>
        <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>
      </div>
      <div style="color:#fff">
        <div class="p-10 mt-10 mb-10" :style="'background:' + (notas[0] || notas[1] ? '#FFFF00' : '#ccc') + '; color:#000;'">
          <h3 class="text-center">NOTAS MANHÃ</h3>
          <ol class="p-20">
            <li class="mao text-left" :style="'border-bottom:solid 1px #' + (dt.duration_minutes > 30 ? '000' : 'fff')" @click="editNota(dt, index)" v-for="(dt, index) in notas" :key="index" v-html="(dt.observation || '').toString().replaceAll('\n', '<br />')"></li>
          </ol>
          <h3 class="text-center">NOTAS TARDE</h3>
          <ol class="p-20">
            <li class="mao text-left" :style="'border-bottom:solid 1px #' + (dt.duration_minutes > 30 ? '000' : 'fff')" @click="editNota(dt, index)" v-for="(dt, index) in notas1" :key="index" v-html="(dt.observation || '').toString().replaceAll('\n', '<br />')"></li>
          </ol>
          <div class="form-group" data-plugin="formMaterial" v-if="addNota">
            <textarea rows="5" class="form-control" v-model="nota" />
            <div class="checkbox-custom m-0 checkbox-primary float-left" @keyup.enter.prevent="FOCO">
              <input class="foco" type="checkbox" id="fdsDSd-divulgar" v-model="divulgar">
              <label for="fdsDSd-divulgar">Divulgar</label>
            </div>
          </div>
          <button v-if="addNota" @click="addNota = 0; nota = ''; notaID = '';" class="btn btn-default btn-block">Cancelar</button>
          <button v-if="addNota && !notaID" @click="GuardarNota()" class="btn btn-block btn-primary">Guardar Nota Manhã</button>
          <button v-if="addNota && !notaID" @click="GuardarNota(1)" class="btn btn-block btn-primary">Guardar Nota Tarde</button>

          <button v-if="addNota && notaID" @click="GuardarNota()" class="btn btn-block btn-primary">Atualizar Nota</button>
          <button v-if="!addNota" @click="addNota = 1; nota = ''; notaID = '';" class="btn btn-block btn-warning">Adicionar Nota</button>
        </div>

      </div>
    </div>
    <div class="balizas-tabela cinzento1">
      <h3 class="ml-20">{{tottalValidados}} Alocações com o Total de: {{total.toFixed(2)}} €<button @click="$router.push('/balizaslandia')" class="btn btn-danger ml-20">Sair</button></h3>
      <div class="cinzento1">
        <table>
          <tr>
            <td style="vertical-align:top">
              <table class="table balizas" style="min-width: 940px;max-width: calc(100% - 500px)">
                <thead>
                  <tr>
                    <th style="top: 0px;min-width:120px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Monitor</th>
                    <th style="top: 0px;width:33px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">M</th>
                    <th style="top: 0px;width:33px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">T</th>
                    <th style="top: 0px;width:33px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">I</th>
                    <th style="top: 0px;min-width: 160px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Observaçções<br />Monitores / Gerência</th>
                    <th style="top: 0px;width:70px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Data<br />Disp.</th>
                    <th style="top: 0px;width:200px;" :class="{'meio text-center break': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Manhã</th>
                    <th style="top: 0px;width:200px;" :class="{'meio text-center break': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Tarde</th>
                    <th style="top: 0px;width:40px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Estado</th>
                  </tr>
                </thead>
                <tbody style="color:#000;">
                  <template v-for="(dt, index) in users.filter(a => a.monitores_fixos)">
                    <tr :key="'aa1-' + index" v-if="!res.find(a => dt.email == a.eventable.email) && dt.ativo > 0 && (dt.balizaslandia && $store.state.balizaslandia === 'smf' || dt.balizaslandia_oaz && $store.state.balizaslandia === 'oaz')" class="azul1">
                      <td class="meio">{{dt.name}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="meio"><textarea style="min-width: 150px;" row="2" type="text" class="form-control" placeholder="" v-model="dt.obs" @change="dt.up = 1;$forceUpdate();"></textarea></td>
                      <td></td>
                      <td class="meio text-center">
                            <input type="time" step="900" style="margin:1px;" v-model="dt.manha_inicio" @change="dt.up = 1;$forceUpdate();" />
                            <input type="time" step="900" style="margin:1px;" v-model="dt.manha_fim" @change="dt.up = 1;$forceUpdate();" />
                            <select class="mt-5 form-control" v-model="dt.local" @change="dt.up = 1;$forceUpdate();">
                              <option v-for="(x, ind) in locais" :key="index + 'S-' + ind">{{x}}</option>
                            </select>
                      </td>
                      <td class="meio text-center">
                          <input type="time" step="900" style="margin:1px;" v-model="dt.tarde_inicio" @change="dt.up = 1;$forceUpdate();" />
                          <input type="time" step="900" cstyle="margin:1px;" v-model="dt.tarde_fim" @change="dt.up = 1;$forceUpdate();" />
                          <select class="mt-5 form-control" v-model="dt.local1" @change="dt.up = 1;$forceUpdate();">
                            <option v-for="(x, ind) in locais" :key="index + 'AS-' + ind">{{x}}</option>
                          </select>
                     </td>
                      <td class="meio text-center">
                        <div v-if="dt.up">
                          <span class="text-warning" v-if="!dt.validado && dt.ok && !dt.enviado">Guardado</span>
                          <a @click="submitMonitor1(dt, 0, 0, 1); dt.up = 0;" class="btn btn-block btn-primary" href="javascript:void(0)" v-if="dt.up">Guardar</a>
                          <a @click="submitMonitor1(dt, 1); dt.up = 0;" class="btn btn-block btn-primary mt-5" href="javascript:void(0)" v-if="dt.up">Enviar</a>
                          <a @click="submitMonitor1(dt, 1, 1); dt.up = 0;" class="btn btn-block btn-primary mt-5" href="javascript:void(0)" v-if="dt.up">Direto</a>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <tr v-for="(dt, index) in res" :key="index" :class="{'verdeX': dt.validado == 1 || dt.faltou, 'amarelo1': !dt.up && !dt.validado && dt.enviado && !dt.faltou, 'vermelhoX': dt.validado == -1, 'Cinzento': dt.indisp, 'cinzentoX': !dt.up && dt.guardou && !dt.enviado && !dt.faltou && !dt.validado }">
                    <td class="meio">
                      {{dt.eventable.name}}<br />
                      <a @click="apagar(dt)" class="btn btn-block btn-danger mt-5" href="javascript:void(0)" v-if="dt.manha_inicio || dt.tarde_inicio">Apagar</a>
                    </td>
                    <td class="meio text-center" v-html="dt.event_at.split(' ')[1] === '09:00' ? check() : ''"></td>
                    <td class="meio text-center" v-html="dt.event_at.split(' ')[1] === '13:00' || dt.duration_minutes == 90 ? check() : ''"></td>
                    <td class="meio text-center" v-html="dt.indisp ? check() : ''"></td>

                    <td class="meio"><span v-html="dt.observation"></span><br /><textarea style="min-width: 150px;" row="2" type="text" class="form-control" placeholder="" v-model="dt.obs" @change="dt.up = 1;$forceUpdate();"></textarea></td>
                    <td class="meio" v-html="calculardataerro(dt)"></td>
                    <td class="meio text-center">
                          <input type="time" step="900" style="margin:1px;" v-model="dt.manha_inicio" @change="dt.up = 1;$forceUpdate();" />
                          <input type="time" step="900" cstyle="margin:1px;" v-model="dt.manha_fim" @change="dt.up = 1;$forceUpdate();" />
                          <select class="mt-5 form-control" v-model="dt.local" @change="(dt.enviado || dt.faltou) ? dt.up = 2 : dt.up = 1;$forceUpdate();">
                            <option v-for="(x, ind) in locais" :key="index + '-' + ind">{{x}}</option>
                          </select>
                    </td>
                    <td class="meio text-center">
                        <input type="time" step="900" style="margin:1px;" v-model="dt.tarde_inicio" @change="dt.up = 1;$forceUpdate();" />
                        <input type="time" step="900" style="margin:1px;" v-model="dt.tarde_fim" @change="dt.up = 1;$forceUpdate();" />
                        <select class="mt-5 form-control" v-model="dt.local1" @change="(dt.enviado || dt.faltou) ? dt.up = 2 : dt.up = 1;$forceUpdate();">
                          <option v-for="(x, ind) in locais" :key="index + 'A-' + ind">{{x}}</option>
                        </select>
                   </td>
                    <td class="meio text-center">
                      <span v-if="!dt.up && dt.faltou" class="text-success">Alterado</span>
                      <span v-if="!dt.up && !dt.validado && dt.enviado && !dt.faltou" class="text-warning">Aguarda validação</span>
                      <span class="verm" v-if="dt.validado == -1 && dt.enviado">Não Validado</span>
                      <span class="text-success" v-if="!dt.up && dt.validado == 1 && !dt.faltou">Validado</span>
                      <span class="text-warning" v-if="!dt.up && dt.guardou && !dt.enviado && !dt.faltou && !dt.validado">Guardado</span>
                      <a @click="submitMonitor(dt, 0, 0, 1);" class="btn btn-block btn-primary" href="javascript:void(0)" v-if="dt.up == 1 && !dt.faltou && (dt.manha_inicio || dt.tarde_inicio)">Guardar</a>
                      <a @click="submitMonitor(dt, 1, 0);" class="btn btn-block btn-primary mt-5" href="javascript:void(0)" v-if="(dt.up == 1 || (!dt.validado && dt.id || dt.ok) && !dt.enviado) && (dt.manha_inicio || dt.tarde_inicio)">Enviar</a>
                      <a @click="submitMonitor(dt
                      , 1, 1);" class="btn btn-block btn-primary mt-5" href="javascript:void(0)" v-if="dt.up == 1">Direto</a>
                      <a @click="submitMonitorLocal(dt)" v-if="dt.up == 2" class="btn btn-block btn-primary mt-5" href="javascript:void(0)">Local</a>
                    </td>
                  </tr>

                  <template v-for="(dt, index) in users.filter(a => !a.monitores_fixos)">
                    <tr :key="'aa-' + index" v-if="!res.find(a => dt.email == a.eventable.email) && dt.ativo > 0 && (dt.balizaslandia && $store.state.balizaslandia === 'smf' || dt.balizaslandia_oaz && $store.state.balizaslandia === 'oaz')" class="cinzento1">
                      <td class="meio">{{dt.name}}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="meio"><textarea style="min-width: 150px;" row="2" type="text" class="form-control" placeholder="" v-model="dt.obs" @change="dt.up = 1;$forceUpdate();"></textarea></td>
                      <td></td>
                      <td class="meio text-center">
                            <input type="time" step="900" style="margin:1px;" v-model="dt.manha_inicio" @change="dt.up = 1;$forceUpdate();" />
                            <input type="time" step="900" style="margin:1px;" v-model="dt.manha_fim" @change="dt.up = 1;$forceUpdate();" />
                            <select class="mt-5 form-control" v-model="dt.local" @change="dt.up = 1;$forceUpdate();">
                              <option v-for="(x, ind) in locais" :key="index + 'S-' + ind">{{x}}</option>
                            </select>
                      </td>
                      <td class="meio text-center">
                          <input type="time" step="900" style="margin:1px;" v-model="dt.tarde_inicio" @change="dt.up = 1;$forceUpdate();" />
                          <input type="time" step="900" cstyle="margin:1px;" v-model="dt.tarde_fim" @change="dt.up = 1;$forceUpdate();" />
                          <select class="mt-5 form-control" v-model="dt.local1" @change="dt.up = 1;$forceUpdate();">
                            <option v-for="(x, ind) in locais" :key="index + 'AS-' + ind">{{x}}</option>
                          </select>
                     </td>
                      <td class="meio text-center">
                        <div v-if="dt.up">
                          <span class="text-warning" v-if="!dt.validado && dt.ok && !dt.enviado">Guardado</span>
                          <a @click="submitMonitor1(dt, 0, 0, 1); dt.up = 0;" class="btn btn-block btn-primary" href="javascript:void(0)" v-if="dt.up">Guardar</a>
                          <a @click="submitMonitor1(dt, 1); dt.up = 0;" class="btn btn-block btn-primary mt-5" href="javascript:void(0)" v-if="dt.up">Enviar</a>
                          <a @click="submitMonitor1(dt, 1, 1); dt.up = 0;" class="btn btn-block btn-primary mt-5" href="javascript:void(0)" v-if="dt.up">Direto</a>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </td>
            <td style="vertical-align:top" class="horarios">
              <table class="table balizas" style="width: 550px;position: fixed; top: 70px; right: 10px;">
                <thead>
                  <tr>
                    <th class="text-left" style="width: 100px;">Local</th>
                    <th class="text-left">Monitor</th>
                    <th style="width: 32px;" class="p-0">8</th>
                    <th style="width: 32px;" class="p-0">9</th>
                    <th style="width: 32px;" class="p-0">10</th>
                    <th style="width: 32px;" class="p-0">11</th>
                    <th style="width: 32px;" class="p-0">12</th>
                    <th style="width: 32px;" class="p-0">13</th>
                    <th style="width: 32px;" class="p-0">14</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dt, index) in manha" :key="index" :style="dt.camisola ? 'border: solid 3px #fdfd96' : ''">
                    <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais, 'cinzento texto-branco': dt.mais}">{{dt.local}}</td>
                    <td v-else class="p-0">{{dt}}</td>
                    <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais, 'cinzento texto-branco': dt.mais}">{{ dt.eventable.name }}</td>
                    <td v-else class="p-0"></td>
                    <td v-if="dt.id" class="p-0 pr" colspan="7">
                      <div class="pp1_"></div><div class="pp2_"></div><div class="pp3_"></div><div class="pp4_"></div><div class="pp5_"></div><div class="pp6_"></div>
                      <div :style="calcManha(dt)"></div>
                    </td>
                    <td v-else class="p-0 pr" colspan="7">
                      <div class="pp1_"></div><div class="pp2_"></div><div class="pp3_"></div><div class="pp4_"></div><div class="pp5_"></div><div class="pp6_"></div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="table balizas" style="width: 550px;position: fixed; right: 10px; bottom: 10px;">
                <thead>
                  <tr>
                    <th class="text-left" style="width: 100px;">Local</th>
                    <th class="text-left">Monitor</th>
                    <th style="width: 32px;" class="p-0">12</th>
                    <th style="width: 32px;" class="p-0">13</th>
                    <th style="width: 32px;" class="p-0">14</th>
                    <th style="width: 32px;" class="p-0">15</th>
                    <th style="width: 32px;" class="p-0">16</th>
                    <th style="width: 32px;" class="p-0">17</th>
                    <th style="width: 32px;" class="p-0">18</th>
                    <th style="width: 32px;" class="p-0">19</th>
                    <th style="width: 32px;" class="p-0">20</th>
                    <th style="width: 32px;" class="p-0">21</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(dt, index) in tarde" :key="index" :style="dt.camisola1 ? 'border: solid 3px #fdfd96' : ''">
                    <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais1, 'cinzento texto-branco': dt.mais1}">{{dt.local1}}</td>
                    <td v-else class="p-0">{{dt}}</td>
                    <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais1, 'cinzento texto-branco': dt.mais1}">{{ dt.eventable.name }}</td>
                    <td v-else class="p-0"></td>
                    <td v-if="dt.id" class="p-0 pr" colspan="10">
                      <div class="pp1"></div><div class="pp2"></div><div class="pp3"></div><div class="pp4"></div><div class="pp5"></div><div class="pp6"></div><div class="pp7"></div><div class="pp8"></div><div class="pp9"></div>
                      <div :style="calcTarde(dt)"></div>
                    </td>
                    <td v-else class="p-0 pr" colspan="10">
                      <div class="pp1"></div><div class="pp2"></div><div class="pp3"></div><div class="pp4"></div><div class="pp5"></div><div class="pp6"></div><div class="pp7"></div><div class="pp8"></div><div class="pp9"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>

        <table class="table balizas horariosXS" style="width: 500px;margin: 0 auto;">
          <thead>
            <tr>
              <th class="text-left width-120">Local</th>
              <th class="text-left">Monitor</th>
              <th class="text-left">Entrada</th>
              <th class="text-left">Saída</th>
              <th style="width: 32px;" class="p-0">8</th>
              <th style="width: 32px;" class="p-0">9</th>
              <th style="width: 32px;" class="p-0">10</th>
              <th style="width: 32px;" class="p-0">11</th>
              <th style="width: 32px;" class="p-0">12</th>
              <th style="width: 32px;" class="p-0">13</th>
              <th style="width: 32px;" class="p-0">14</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dt, index) in manha" :key="index" :style="dt.camisola ? 'border: solid 3px #fdfd96' : ''">
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais, 'cinzento texto-branco': dt.mais}">{{dt.local}}</td>
              <td v-else class="p-0">{{dt}}</td>
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais, 'cinzento texto-branco': dt.mais}">{{ dt.eventable.name }}</td>
              <td v-else class="p-0"></td>
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais, 'cinzento texto-branco': dt.mais}">{{ dt.manha_inicio }}</td>
              <td v-else class="p-0"></td>
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais, 'cinzento texto-branco': dt.mais}">{{ dt.manha_fim }}</td>
              <td v-else class="p-0"></td>
              <td v-if="dt.id" class="p-0 pr" colspan="7">
                <div class="pp1_"></div><div class="pp2_"></div><div class="pp3_"></div><div class="pp4_"></div><div class="pp5_"></div><div class="pp6_"></div>
                <div :style="calcManha(dt)"></div>
              </td>
              <td v-else class="p-0 pr" colspan="7">
                <div class="pp1_"></div><div class="pp2_"></div><div class="pp3_"></div><div class="pp4_"></div><div class="pp5_"></div><div class="pp6_"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="table balizas horariosXS" style="width: 500px;margin: 10px auto;">
          <thead>
            <tr>
              <th class="text-left width-120">Local</th>
              <th class="text-left">Monitor</th>
              <th class="text-left">Entrada</th>
              <th class="text-left">Saída</th>
              <th style="width: 32px;" class="p-0">12</th>
              <th style="width: 32px;" class="p-0">13</th>
              <th style="width: 32px;" class="p-0">14</th>
              <th style="width: 32px;" class="p-0">15</th>
              <th style="width: 32px;" class="p-0">16</th>
              <th style="width: 32px;" class="p-0">17</th>
              <th style="width: 32px;" class="p-0">18</th>
              <th style="width: 32px;" class="p-0">19</th>
              <th style="width: 32px;" class="p-0">20</th>
              <th style="width: 32px;" class="p-0">21</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dt, index) in tarde" :key="index" :style="dt.camisola1 ? 'border: solid 3px #fdfd96' : ''">
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais1, 'cinzento texto-branco': dt.mais1}">{{dt.local1}}</td>
              <td v-else class="p-0">{{dt}}</td>
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais1, 'cinzento texto-branco': dt.mais1}">{{ dt.eventable.name }}</td>
              <td v-else class="p-0"></td>
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais1, 'cinzento texto-branco': dt.mais1}">{{ dt.tarde_inicio }}</td>
              <td v-else class="p-0"></td>
              <td v-if="dt.id" :class="{'p-0 pl-5 text-left meio': 1, 'cinzento1': !dt.mais1, 'cinzento texto-branco': dt.mais1}">{{ dt.tarde_fim }}</td>
              <td v-else class="p-0"></td>
              <td v-if="dt.id" class="p-0 pr" colspan="10">
                <div class="pp1"></div><div class="pp2"></div><div class="pp3"></div><div class="pp4"></div><div class="pp5"></div><div class="pp6"></div><div class="pp7"></div><div class="pp8"></div><div class="pp9"></div>
                <div :style="calcTarde(dt)"></div>
              </td>
              <td v-else class="p-0 pr" colspan="10">
                <div class="pp1"></div><div class="pp2"></div><div class="pp3"></div><div class="pp4"></div><div class="pp5"></div><div class="pp6"></div><div class="pp7"></div><div class="pp8"></div><div class="pp9"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { searchEvent, patchEventClient, feriados, empresaUsers, createEventClient } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { commonFunctionsMixin } from '../filters/funcoes'

export default {
  name: 'pageDistribuicao',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      res: [],
      year: '',
      month: '',
      day: '',
      attributes: [{
        dates: new Date(),
        key: 'today',
        highlight: true
      }],
      users: [],
      nota: '',
      divulgar: 0,
      addNota: 0,
      notas: [],
      notas1: [],
      notaID: '',
      locais: ['', 'Balcão', 'Lanche', 'Cacifos', 'Cacifos/Lanche', 'Parque', 'Bar', 'Camisola'],
      // 'Balcão', 'Balcão', 'Lanche', 'Lanche', 'Lanche', 'Lanche', 'Cacifos', 'Parque', 'Parque', 'Parque', 'Parque', 'Parque', 'Bar'
      manha: [],
      tarde: [],

      total: 0,

      tottalValidados: 0
    }
  },
  mounted () {
    this.SET_TITLE('')
    this.SET_LOAD(true)
    let a = new Date()
    let xxx = decodeURIComponent(window.location.search).split('d=')[1].split(' ')[0] || decodeURIComponent(window.location.search).substring(3)
    if (xxx) {
      a = new Date(xxx.replace(/-/g, '/'))
      this.attributes[0].dates = a
      this.$refs.calendar.move(a)
    }
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'SET_BALIZAS'
    ]),
    apagar (dt) {
      let self = this
      this.$modal.show('dialog', {
        title: '<i class="danger icon wb-alert-circle mr-10"></i> Deseja apagar o horário?',
        buttons: [
        {
          title: 'Não',
          handler: () => {
            this.$modal.hide('dialog')
          },
          class: 'btn btn-default'
        },
        {
          title: 'Sim',
          default: true,
          handler: () => {
            dt.meta = {}
            dt.meta['validado'] = 0
            dt.meta['pinhata_tema1'] = ''
            dt.meta['hostia_tema'] = ''
            dt.meta['valor recebido'] = 0

            dt.meta['Donuts'] = 0
            dt.meta['Crossaints'] = 0
            dt.meta['Pizzas'] = 0
            dt.meta['manha'] = ''
            dt.meta['tarde'] = ''
            self.SET_LOAD(true)
            patchEventClient(self.axios, dt).then(() => {
              self.formUrl()
              self.SET_TOAST({ msg: self.$t('Horário apagado com sucesso') })
              self.SET_LOAD()
            }, (er) => {
              self.SET_TOAST({ msg: self.$t(er.response.data.error), type: 'error' })
            })
            this.$modal.hide('dialog')
          },
          class: 'btn btn-danger'
        }
        ]
      })
    },
    calculardataerro (dt) {
      let a = new Date(this.year + '-' + this.month + '-' + this.day)
      // let c = new Date(this.year + '-' + this.month + '-' + this.day)
      a.setDate(a.getDate() - a.getDay() - 4)

      /*
      if (new Date() < new Date(b.getFullYear() + '-' + ('0' + (1 + b.getMonth())).slice(-2) + '-' + ('0' + b.getDate()).slice(-2) + ' 12:00:00')) {
        this.validacao = new Date(a.setDate(a.getDate() - a.getDay() + 7))
      } else {
        this.validacao = new Date(a.setDate(a.getDate() - a.getDay() + 14))
      }
      */

      if (a < new Date(dt.created_at)) {
        return '<span class="text-danger">' + dt.created_at.substring(8, 10) +  ' ' + this.$t('meses.' + parseInt(dt.created_at.substring(5, 7))).substring(0, 3)
      }

      /* let a = new Date(this.year + '-' + this.month + '-' + this.day)
      if (new Date(a.setDate(a.getDate() - a.getDay() + 14)) > new Date(dt.updated_at)) {
        return '<span class="text-danger">' + dt.updated_at.substring(8, 10) +  ' ' + this.$t('meses.' + dt.updated_at.substring(5, 7)).substring(0, 3)
      }
      */
      return dt.created_at.substring(8, 10) +  ' ' + this.$t('meses.' + parseInt(dt.created_at.substring(5, 7))).substring(0, 3)
    },
    init () {
      this.formUrl()
      feriados(this.axios, this.year).then((res) => {
        this.holidays = res.data.GetNationalHolidaysResult.Holiday
        this.holidays.map(a => {
          this.attributes.push({
            bar: 'red',
            dates: new Date(a.Date.substring(0, 10).replace(/-/g, '/'))
          })
        })
        let ano = this.year
        this.attributes.push({
          bar: 'red',
          dates: new Date(ano + '/01/20')
        })
        ++ano
        this.attributes.push({
          bar: 'red',
          dates: new Date(ano + '/01/20')
        })
        this.attributes.push({
          bar: 'red',
          dates: new Date(ano + '/01/01')
        })
      }, () => {
        this.SET_TOAST({ msg: 'Não foi possível carregar os feriados', type: 'danger' })
      })
      empresaUsers(this.axios).then((res) => {
        this.users = res.data.filter(a => a.permissions.is_monitor > 0 && ((a.balizaslandia > 0 && this.$store.state.balizaslandia === 'smf') || (a.balizaslandia_oaz > 0 && this.$store.state.balizaslandia === 'oaz')) && parseInt(a.ativo) === 1).sort((a,b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        this.usersFN()
      }, () => {})
    },
    usersFN () {
      const dia = new Array ('domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado')
      const dd = new Date(this.year + '-' + this.month + '-' + this.day)
      let sem = dia[dd.getDay()]
      this.users = this.users.map(a => {
        if (a.monitores_fixos && a.extra_data) {
          let x = JSON.parse(a.extra_data)
          if (x[sem + '_'] === this.$store.state.balizaslandia) {
            a.manha_inicio = x[sem + '1']
            a.manha_fim = x[sem + '2']
            a.tarde_inicio = x[sem + '3']
            a.tarde_fim = x[sem + '4']
            if (a.manha_inicio && a.manha_fim) {
              a.local = 'Balcão'
            }
            if (a.tarde_inicio && a.tarde_fim) {
              a.local1 = 'Balcão'
            }
          }
        }
        return a
      })
    },
    formUrl () {
      this.SET_LOAD(true)
      let cal = []
      switch (this.$store.state.balizaslandia) {
        case 'smf':
          cal = [66, 89, 116]
          break
        case 'oaz':
          cal = [109, 114, 117]
          break
      }
      this.usersFN()
      searchEvent(this.axios, cal, '', '', '', this.year + '-' + this.month + '-' + this.day, this.year + '-' + this.month + '-' + this.day, '', '').then((res) => {
        this.notas = res.data.filter(a => parseInt(a.calendar_id) === cal[1])
        this.notas1 = res.data.filter(a => parseInt(a.calendar_id) === cal[2])
        res.data = res.data.filter(a => parseInt(a.calendar_id) === cal[0])

        this.tottalValidados = res.data.filter(a => this.meta(a, 'validado') || this.meta(a, 'Donuts') || (this.meta(a, 'Pizzas') && !this.meta(a, 'Crossaints') && !this.meta(a, 'Donuts') && !this.meta(a, 'validado')) || !this.meta(a, 'validado') && this.meta(a, 'Crossaints') && !this.meta(a, 'Donuts')).length
        this.total = 0
        let i = res.data.length
        let mt = []
        let m = []
        let t = []
        let ult = []
        let x
        while (--i >= 0) {
          if ('undefined#undefined' === this.meta(res.data[i], 'manha')) {
            res.data[i].manha_inicio = ''
            res.data[i].manha_fim = ''
          } else {
            res.data[i].manha_inicio = this.meta(res.data[i], 'manha').split('#')[0] || ''
            res.data[i].manha_fim = this.meta(res.data[i], 'manha').split('#')[1] || ''

            this.total += ((new Date('2024-01-01 ' + res.data[i].manha_fim).getTime() - new Date('2024-01-01 ' + res.data[i].manha_inicio).getTime()) / (1000 * 60 * 60)) * this.meta(res.data[i], 'valor recebido') || 0
          }
          if ('undefined#undefined' === this.meta(res.data[i], 'tarde')) {
            res.data[i].tarde_inicio = ''
            res.data[i].tarde_fim = ''
          } else {
            res.data[i].tarde_inicio = this.meta(res.data[i], 'tarde').split('#')[0] || ''
            res.data[i].tarde_fim = this.meta(res.data[i], 'tarde').split('#')[1] || ''

            this.total += ((new Date('2024-01-01 ' + res.data[i].tarde_fim).getTime() - new Date('2024-01-01 ' + res.data[i].tarde_inicio).getTime()) / (1000 * 60 * 60)) * this.meta(res.data[i], 'valor recebido') || 0
          }

          x = res.data[i].event_at.split(' ')[1]
          res.data[i].event_at = res.data[i].event_at.substring(0, 16)
          res.data[i].faltou = this.meta(res.data[i], 'Donuts')
          res.data[i].validado = this.meta(res.data[i], 'validado')
          res.data[i].ok = 0
          res.data[i].up = 0
          res.data[i].enviado = this.meta(res.data[i], 'Crossaints')
          res.data[i].local = this.meta(res.data[i], 'pinhata_tema1')
          res.data[i].local1 = this.meta(res.data[i], 'hostia_tema')
          res.data[i].obs = this.meta(res.data[i], 'Obvs')
          res.data[i].guardou = this.meta(res.data[i], 'Pizzas')
          res.data[i].indisp = this.meta(res.data[i], 'Nuggets')

          if (res.data[i].indisp) {
            ult.push(res.data[i])
          }

          if ((this.meta(res.data[i], 'manha') && 'undefined#undefined' !== this.meta(res.data[i], 'manha') && this.meta(res.data[i], 'tarde') && 'undefined#undefined' !== this.meta(res.data[i], 'tarde')) || (x === '09:00:00' && parseInt(res.data[i].duration_minutes) === 90)) {
            mt.push(res.data[i])
            continue
          }
          if (this.meta(res.data[i], 'tarde') && 'undefined#undefined' !== this.meta(res.data[i], 'tarde') || x === '13:00:00') {
            t.push(res.data[i])
            continue
          }
          if (this.meta(res.data[i], 'manha') && 'undefined#undefined' !== this.meta(res.data[i], 'manha') || x === '09:00:00') {
            m.push(res.data[i])
            continue
          }
        }
        this.res = mt.concat(m, t, ult)
        this.compor()
        this.SET_LOAD()
      })
    },
    compor () {
      let i = this.res.length, bar = [], balcao = [], parque = [], lanche = [], cacifos = [], bar1 = [], balcao1 = [], parque1 = [], lanche1 = [], cacifos1 = [], lancheCacifos1 = [], lancheCacifos = []
      while (--i >= 0) {
        if (this.res[i].manha_inicio) {
          switch (this.res[i].local) {
            case 'Balcão':
              balcao.push(this.res[i])
              break
            case 'Lanche':
              lanche.push(this.res[i])
              break
            case 'Cacifos':
              cacifos.push(this.res[i])
              break
            case 'Cacifos/Lanche':
              lancheCacifos.push(this.res[i])
              break
            case 'Parque':
              parque.push(this.res[i])
              break
            case 'Bar':
              bar.push(this.res[i])
              break
            case 'Camisola':
              // camisola.push(this.res[i])
              this.res[i].local  = 'Parque'
              this.res[i].camisola = 1
              parque.push(this.res[i])
              break
          }
        }
        if (this.res[i].tarde_inicio) {
          switch (this.res[i].local1) {
            case 'Balcão':
              balcao1.push(this.res[i])
              break
            case 'Lanche':
              lanche1.push(this.res[i])
              break
            case 'Cacifos':
              cacifos1.push(this.res[i])
              break
            case 'Cacifos/Lanche':
              lancheCacifos1.push(this.res[i])
              break
            case 'Parque':
              parque1.push(this.res[i])
              break
            case 'Bar':
              bar1.push(this.res[i])
              break
            case 'Camisola':
              // camisola1.push(this.res[i])
              this.res[i].local1  = 'Parque'
              this.res[i].camisola1 = 1
              parque1.push(this.res[i])
              break
          }
        }
      }
      i = 2
      while (balcao[i]) {
        balcao[i].mais = 1
        ++i
      }
      i = 3
      while (balcao1[i]) {
        balcao1[i].mais1 = 1
        ++i
      }
      i = 5
      while (parque[i]) {
        parque[i].mais = 1
        ++i
      }
      i = 5
      while (parque1[i]) {
        parque1[i].mais1 = 1
        ++i
      }
      i = 3
      while (lanche[i]) {
        lanche[i].mais = 1
        ++i
      }
      i = 3
      while (lanche1[i]) {
        lanche1[i].mais1 = 1
        ++i
      }
      i = 1
      while (lancheCacifos[i]) {
        lancheCacifos[i].mais = 1
        ++i
      }
      i = 0
      while (lancheCacifos1[i]) {
        lancheCacifos1[i].mais1 = 1
        ++i
      }
      i = 1
      while (bar[i]) {
        bar[i].mais = 1
        ++i
      }
      i = 1
      while (bar1[i]) {
        bar1[i].mais1 = 1
        ++i
      }
      i = 1
      while (cacifos[i]) {
        cacifos[i].mais = 1
        ++i
      }
      i = 1
      while (cacifos1[i]) {
        cacifos1[i].mais1 = 1
        ++i
      }

      this.manha = []
      this.tarde = []
      // 'Balcão', 'Balcão', 'Lanche', 'Lanche', 'Lanche', 'Lanche', 'Cacifos', 'Parque', 'Parque', 'Parque', 'Parque', 'Parque', 'Bar'
      switch (balcao.length) {
        case 0:
          this.manha.push('Balcão')
          this.manha.push('Balcão')
          break
        case 1:
          this.manha = this.manha.concat(balcao)
          this.manha.push('Balcão')
          break
        default:
          this.manha = this.manha.concat(balcao)
      }
      switch (lanche.length) {
        case 0:
          this.manha.push('Lanche')
          this.manha.push('Lanche')
          this.manha.push('Lanche')
          break
        case 1:
          this.manha = this.manha.concat(lanche)
          this.manha.push('Lanche')
          this.manha.push('Lanche')
          break
        case 2:
          this.manha = this.manha.concat(lanche)
          this.manha.push('Lanche')
          break
        default:
          this.manha = this.manha.concat(lanche)
      }
      switch (cacifos.length) {
        case 0:
          this.manha.push('Cacifos')
          break
        default:
          this.manha = this.manha.concat(cacifos)
      }
      switch (lancheCacifos.length) {
        case 0:
          this.manha.push('Cacifos/Lanche')
          break
        default:
          this.manha = this.manha.concat(lancheCacifos)
      }
      switch (parque.length) {
        case 0:
          this.manha.push('Parque')
          this.manha.push('Parque')
          this.manha.push('Parque')
          this.manha.push('Parque')
          this.manha.push('Parque')
          break
        case 1:
          this.manha = this.manha.concat(parque)
          this.manha.push('Parque')
          this.manha.push('Parque')
          this.manha.push('Parque')
          this.manha.push('Parque')
          break
        case 2:
          this.manha = this.manha.concat(parque)
          this.manha.push('Parque')
          this.manha.push('Parque')
          this.manha.push('Parque')
          break
        case 3:
          this.manha = this.manha.concat(parque)
          this.manha.push('Parque')
          this.manha.push('Parque')
          break
        case 4:
          this.manha = this.manha.concat(parque)
          this.manha.push('Parque')
          break
        default:
          this.manha = this.manha.concat(parque)
          break
      }
      switch (bar.length) {
        case 0:
          this.manha.push('Bar')
          break
        default:
          this.manha = this.manha.concat(bar)
      }
      /*
      switch (camisola.length) {
        case 0:
          this.manha.push('Camisola')
          break
        default:
          this.manha = this.manha.concat(camisola)
      }
      */

      switch (balcao1.length) {
        case 0:
          this.tarde.push('Balcão')
          this.tarde.push('Balcão')
          this.tarde.push('Balcão')
          break
        case 2:
          this.tarde = this.tarde.concat(balcao1)
          this.tarde.push('Balcão')
          break
        case 1:
          this.tarde = this.tarde.concat(balcao1)
          this.tarde.push('Balcão')
          this.tarde.push('Balcão')
          break
        default:
          this.tarde = this.tarde.concat(balcao1)
      }
      switch (lanche1.length) {
        case 0:
          this.tarde.push('Lanche')
          this.tarde.push('Lanche')
          this.tarde.push('Lanche')
          break
        case 1:
          this.tarde = this.tarde.concat(lanche1)
          this.tarde.push('Lanche')
          this.tarde.push('Lanche')
          break
        case 2:
          this.tarde = this.tarde.concat(lanche1)
          this.tarde.push('Lanche')
          break
        default:
          this.tarde = this.tarde.concat(lanche1)
      }
      switch (cacifos1.length) {
        case 0:
          this.tarde.push('Cacifos')
          break
        default:
          this.tarde = this.tarde.concat(cacifos1)
      }
      if (lancheCacifos1.length) {
        /*
        case 0:
          this.tarde.push('Cacifos/Lanche')
          break
          */
        this.tarde = this.tarde.concat(lancheCacifos1)
      }
      switch (parque1.length) {
        case 0:
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          break
        case 1:
          this.tarde = this.tarde.concat(parque1)
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          break
        case 2:
          this.tarde = this.tarde.concat(parque1)
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          break
        case 3:
          this.tarde = this.tarde.concat(parque1)
          this.tarde.push('Parque')
          this.tarde.push('Parque')
          break
        case 4:
          this.tarde = this.tarde.concat(parque1)
          this.tarde.push('Parque')
          break
        default:
          this.tarde = this.tarde.concat(parque1)
          break
      }
      switch (bar1.length) {
        case 0:
          this.tarde.push('Bar')
          break
        default:
          this.tarde = this.tarde.concat(bar1)
      }
      /*
      switch (camisola1.length) {
        case 0:
          this.tarde.push('Camisola')
          break
        default:
          this.tarde = this.tarde.concat(camisola1)
      }
      */
    },
    onDayClick (dia) {
      if (!dia.id) {
        let a = new Date()
        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)
        dia = { id: this.year + '-' + this.month + '-' + this.day }
      }
      this.attributes[0].dates = new Date(dia.id.replace(/-/g, '/'))
      this.$refs.calendar.move(dia.id)
      this.month = dia.id.split('-')[1]
      this.year = dia.id.split('-')[0]
      this.day = dia.id.split('-')[2]
      this.formUrl()
    },
    submitMonitor (dt, enviar, alterou, guardou) {
      if (dt.indisp) {
        this.SET_TOAST({ msg: 'Apenas deve marcar os dias que tem disponibilidade', type: 'error' })
        return
      }
      dt.meta = {}
      dt.meta['tarde'] = ''
      dt.meta['manha'] = ''
      if (dt.manha_inicio || dt.manha_fim) {
        if (!(dt.manha_inicio && dt.manha_fim)) {
          this.SET_TOAST({ msg: 'O horário da manhã deve ser peenchido o início e o fim', type: 'error' })
          return
        }
        dt.meta['manha'] = dt.manha_inicio + '#' + dt.manha_fim
      }
      if (dt.tarde_inicio || dt.tarde_fim) {
        if (!(dt.tarde_inicio && dt.tarde_fim)) {
          this.SET_TOAST({ msg: 'O horário da tarde ser peenchido o início e o fim', type: 'error' })
          return
        }
        dt.meta['tarde'] = dt.tarde_inicio + '#' + dt.tarde_fim
      }
      if (dt.manha_fim && dt.manha_inicio) {
        if (!dt.local) {
          this.SET_TOAST({ msg: 'Tem que preencher o local da manhã.', type: 'error' })
          return
        }
      }
      if (dt.tarde_fim && dt.tarde_inicio) {
        if (!dt.local1) {
          this.SET_TOAST({ msg: 'Tem que preencher o local da tarde.', type: 'error' })
          return
        }
      }
      if (alterou) {
        dt.faltou = 1;
      }
      dt.meta['Obvs'] = dt.obs
      dt.meta['validado'] = 0
      dt.meta['pinhata_tema1'] = dt.local
      dt.meta['hostia_tema'] = dt.local1
      dt.meta['valor recebido'] = this.users.find(a => a.email === dt.eventable.email).valor

      dt.meta['Donuts'] = dt.faltou
      dt.meta['Crossaints'] = enviar
      dt.meta['Pizzas'] = guardou
      this.SET_LOAD(true)
      patchEventClient(this.axios, dt).then(() => {
        /*
        dt.up = 0
        dt.ok = 1
        this.compor()
        this.$forceUpdate()
        */
        this.formUrl()
        this.SET_TOAST({ msg: this.$t('Horário marcado com sucesso') })
        this.SET_LOAD()
        return true
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    submitMonitor1 (dt, enviar, alterou, guardou) {
      if (alterou) {
        dt.faltou = 1;
      }
      dt.contact = {
        name: dt.name,
        email: dt.email
      }
      dt.duration_minutes = 60
      dt.event_at = this.year + '-' + this.month + '-' + this.day
      if (dt.manha_inicio) {
        dt.event_at += ' 09:00'
      }
      if (!dt.manha_inicio && dt.tarde_inicio) {
        dt.event_at += ' 13:00'
      }
      if (dt.manha_inicio && dt.tarde_inicio) {
        dt.duration_minutes = 90
      }
      dt.meta = {}
      dt.meta['tarde'] = ''
      dt.meta['manha'] = ''
      dt.duration_minutes = 60
      if (dt.manha_inicio || dt.manha_fim) {
        if (!(dt.manha_inicio && dt.manha_fim)) {
          this.SET_TOAST({ msg: 'O horário da manhã deve ser peenchido o início e o fim', type: 'error' })
          return
        }
        dt.meta['manha'] = dt.manha_inicio + '#' + dt.manha_fim
      }
      if (dt.tarde_inicio || dt.tarde_fim) {
        if (!(dt.tarde_inicio && dt.tarde_fim)) {
          this.SET_TOAST({ msg: 'O horário da tarde ser peenchido o início e o fim', type: 'error' })
          return
        }
        dt.meta['tarde'] = dt.tarde_inicio + '#' + dt.tarde_fim
        if (dt.manha_inicio) {
          dt.duration_minutes += 30
        }
      }
      if (dt.manha_fim && dt.manha_inicio) {
        if (!dt.local) {
          this.SET_TOAST({ msg: 'Tem que preencher o local da manhã.', type: 'error' })
          return
        }
      }
      if (dt.tarde_fim && dt.tarde_inicio) {
        if (!dt.local1) {
          this.SET_TOAST({ msg: 'Tem que preencher o local da tarde.', type: 'error' })
          return
        }
      }
      dt.meta['Obvs'] = dt.obs
      dt.meta['validado'] = 0
      dt.meta['pinhata_tema1'] = dt.local
      dt.meta['hostia_tema'] = dt.local1

      dt.meta['Donuts'] = dt.faltou
      dt.meta['Crossaints'] = enviar
      dt.meta['Pizzas'] = guardou

      dt.meta['valor recebido'] = dt.valor

      switch (this.$store.state.balizaslandia) {
        case 'smf':
          dt.calendar_id = 66
          break
        case 'oaz':
          dt.calendar_id = 109
          break
      }
      dt.repeat = 'no'
      dt.title = 'Monitor'
      dt.color = '#009CE0'

      createEventClient(this.axios, dt).then(() => {
        this.formUrl()
        dt.obs = ''
        dt.manha_inicio = ''
        dt.manha_fim = ''
        dt.local = ''
        dt.tarde_inicio = ''
        dt.tarde_fim = ''
        dt.local1 = ''
        dt.validado = 0
        dt.faltou = 0
        dt.enviar = 0
        dt.guardou =0
        dt['valor recebido'] = 0

        this.SET_TOAST({ msg: this.$t('Horário marcado com sucesso') })
        this.SET_LOAD()
        return true
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    submitMonitorLocal (dt) {
      if (dt.indisp) {
        this.SET_TOAST({ msg: 'Apenas deve marcar os dias que tem disponibilidade', type: 'error' })
        return
      }
      let a = {}
      let i = 0
      while (dt.meta[i]) {
        a[dt.meta[i].meta_key] = dt.meta[i].meta_value
        ++i
      }
      a['pinhata_tema1'] = dt.local
      a['hostia_tema'] = dt.local1
      dt.meta = a
      this.SET_LOAD(true)
      patchEventClient(this.axios, dt).then(() => {
        dt.up = 0
        this.SET_TOAST({ msg: this.$t('Local alterado com sucesso.') })
        this.SET_LOAD()
        return true
      }, (er) => {
        this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
      })
    },
    calcularValorFN (dt) {
      let valor = 0
      let a
      if (dt.manha_inicio && dt.manha_fim) {
        a = new Date(new Date('2022-01-01 ' + dt.manha_fim) - new Date('2022-01-01 ' + dt.manha_inicio))
        valor = a.getUTCHours() + a.getUTCMinutes() / 60
      }
      if (dt.tarde_inicio && dt.tarde_fim) {
        a = new Date(new Date('2022-01-01 ' + dt.tarde_fim) - new Date('2022-01-01 ' + dt.tarde_inicio))
        valor += a.getUTCHours() + a.getUTCMinutes() / 60
      }
      return valor > 0 ? valor * (this.res[dt.monitor] ? this.res[dt.monitor].eventable.vat : 0) : ''
    },
    editNota (dt, index) {
      this.notaID = dt
      this.notaID.index = index
      this.nota = dt.observation
      this.divulgar = dt.duration_minutes > 30 ? 1 : 0
      this.addNota = dt.calendar_id
    },
    GuardarNota (op) {
      if (this.nota.length < 6) {
        this.SET_TOAST({ msg: 'Tem que preencher uma nota cno mínimo com 6 caracteres', type: 'error' })
        return
      }
      this.SET_LOAD(true)
      if (this.notaID.id) {
        this.notaID.observation = this.nota
        this.notaID.event_at = this.year + '-' + this.month + '-' + this.day + ' 23:30'
        this.notaID.duration_minutes = this.divulgar ? 60 : 30
        patchEventClient(this.axios, this.notaID).then((res) => {
          if (op || this.addNota === 116 || this.addNota === 117) {
            this.notas1.splice(this.notaID.index, 1)
            this.notas1.push(res.data)
          } else {
            this.notas.splice(this.notaID.index, 1)
            this.notas.push(res.data)
          }
          this.nota = ''
          this.divulgar = 0
          this.addNota = 0
          this.SET_TOAST({ msg: 'Nota atualizada com sucesso', type: 'success' })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      } else {
        let cal = ''
        switch (this.$store.state.balizaslandia) {
        case 'smf':
          cal = op ? 116 : 89
          break
        case 'oaz':
          cal = op ? 117 : 114
          break
        }
        createEventClient(this.axios, { calendar_id: cal, client_id: 17099, color: '#FCDC00', event_at: this.year + '-' + this.month + '-' + this.day + ' 23:30', duration_minutes: this.divulgar ? 60 : 30, observation: this.nota, title: 'Nota', repeat: 'no' }).then((res) => {
          if (op) {
            this.notas1.push(res.data)
          } else {
            this.notas.push(res.data)
          }
          this.nota = ''
          this.divulgar = 0
          this.addNota = 0
          this.SET_TOAST({ msg: 'Nota adicionada com sucesso', type: 'success' })
        }, (er) => {
          this.SET_TOAST({ msg: this.$t(er.response.data.error), type: 'error' })
        })
      }
    }
  }
}
</script>
